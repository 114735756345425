import type { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from 'next';
import { useRouter } from 'next/router';
import React from 'react';

import { getClient } from '../helpers/sanity/server';
import { getIdForPath } from '../helpers/sitemap/getIdForPath';
import { getPathForId } from '../helpers/sitemap/getPathForId';
import { baseLanguage, languages, LanguageType } from '../languages';
import { LoadingPage } from '../layout/pages/LoadingPage';
import { Page } from '../layout/pages/Page';
import { ConfigType, getConfigQuery } from '../queries/config';
import { getFooterQuery, FooterType } from '../queries/footer';
import { getNavigationQuery, NavigationType } from '../queries/navigation';
import { getPageQuery, PageType } from '../queries/page';
import { getSitemapQuery, SitemapItemType, SitemapType } from '../queries/sitemap';
import { WorkableJobType } from '../types';

const IS_PRODUCION = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';

const SlugPage = ({
  config,
  navigation,
  footer,
  page,
  preview,
  sitemap,
  sitemapItem,
  locked,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const router = useRouter();

  if (!page || router.isFallback) return <LoadingPage />;

  return (
    <Page
      navigation={navigation}
      page={page}
      preview={preview}
      footer={footer}
      config={config}
      sitemap={sitemap}
      sitemapItem={sitemapItem}
      locked={locked}
    />
  );
};

export default SlugPage;

type StaticProps = {
  config: ConfigType;
  footer: FooterType;
  navigation: NavigationType;
  notFound?: boolean;
  page: PageType;
  preview?: boolean;
  revalidate?: number;
  sitemap: SitemapType;
  sitemapItem?: SitemapItemType;
  locked?: boolean;
};

/**
 * Get static props:
 * - sitemap for resolving all links
 * - navigation
 * - page
 * - footer
 */

export const getStaticProps: GetStaticProps = async ({
  params,
  preview = false,
  locale,
}) => {
  const { slug = '' } = params;
  let path = Array.isArray(slug) ? `/${slug.join('/')}` : `/${slug}`;
  const finalSlug = slug[slug.length - 1];

  // fetch sitemap
  let sitemap: SitemapType = await getClient(preview).fetch(getSitemapQuery());
  sitemap = sitemap.filter(Boolean);

  if (!sitemap.length) return { notFound: true };

  /**
   * Branch off from sitemap
   * Get workable detail page, this is a dynamic path
   * living at /xxx/jobs_page/job_page
   */
  const parentPath = path.split('/').slice(0, -1).join('/');
  const parentId = getIdForPath(parentPath, sitemap, locale as LanguageType);
  if (parentId === 'page_jobs') {
    // 404 for job template page
    const jobTemplatePageSitemapPath = getPathForId(
      'page_job',
      sitemap,
      locale as LanguageType,
    );
    if (path === jobTemplatePageSitemapPath) return { notFound: true };

    // dynamic job path is job template path as far as the sitemap knows
    path = sitemap.find((page) => page?._id === 'page_job')?.paths?.[locale];
  }

  // get draft page or published page in preview mode
  const sitemapItem = preview
    ? sitemap.find(
        (item) => item.paths[locale] === path && item._id.startsWith(`drafts.`),
      ) || sitemap.find((item) => item.paths[locale] === path)
    : // get published page in production mode
      sitemap?.find((item) => item?.paths?.[locale] === path);

  if (!sitemapItem) return { notFound: true };

  // fetch config
  const config: ConfigType = await getClient(preview).fetch(getConfigQuery(locale));

  // fetch navigation
  let navigation: NavigationType = await getClient(preview).fetch(
    getNavigationQuery(locale),
    {
      sitemap,
      locale,
    },
  );

  // fetch navigation
  const footer: FooterType = await getClient(preview).fetch(getFooterQuery(locale), {
    sitemap,
    locale,
  });

  // fetch page
  const page = await getClient(preview).fetch(getPageQuery(locale), {
    ...sitemapItem,
    sitemap,
    locale,
    slug: finalSlug,
  });

  /**
   * Branch off for job detail page
   * Inject workable job data into page template
   */

  if (page._id === 'page_job') {
    const jobPage: WorkableJobType = await getClient(preview).fetch(
      `*[_type == 'workable.job' && slug.current == '${finalSlug}'][0]`,
    );

    // check if a page isn't published return 404
    if (IS_PRODUCION && jobPage.state !== 'published') {
      return { notFound: true };
    }

    // hero
    if (!page.hero) page.hero = {};
    page.hero.eyebrow = [jobPage.location?.city, jobPage.location?.country]
      .filter(Boolean)
      .join(' - ');
    page.hero.title = jobPage.title;
    page.title = jobPage.title;
    delete page.hero.text;

    // inject job info
    page.modules = page.modules?.map((module) => {
      if (module._type === 'module.jobdetails') {
        module.workableJobData = jobPage;
      }
      if (module._type === 'module.workableapplicationform') {
        module.shortcode = jobPage.shortcode;
      }
      return module;
    });

    // update sitemap item
    languages.forEach(({ id }) => {
      sitemapItem.paths[id] = `${sitemapItem.paths[id]
        .split('/')
        .slice(0, -1)
        .join('/')}/${jobPage.slug.current}`;
      sitemapItem.titles[id] = jobPage.title;
      sitemapItem.excludeFromSitemap[id] = false;
    });
  }

  if (page.navigation) navigation = page.navigation;

  const props: StaticProps = {
    config,
    footer,
    navigation,
    page,
    preview,
    sitemap,
    sitemapItem,
  };

  // if page is locked let no page data leak through
  if (page.locked) {
    props.page.hero = null;
    props.page.modules = [];
    props.page.dialogs = [];
    props.page.seo = { excludeFromSitemap: true };
    props.locked = true;
  }

  if (!page) return { notFound: true };
  if (!page?.hero && !page?.modules?.length && !page.locked) {
    if (!IS_PRODUCION)
      console.log(
        sitemapItem?.paths[baseLanguage],
        `No hero or modules, rendering 404. The page exists, it just has no content.`,
      );
    return { notFound: true };
  }

  return { props, revalidate: 10 };
};

/**
 * Static paths
 */

export const getStaticPaths: GetStaticPaths = async () => {
  const sitemap: SitemapType = await getClient(false).fetch(getSitemapQuery());

  const paths = sitemap
    .filter(Boolean)
    .reduce(
      (acc, curr) => [
        ...languages.map(({ id }) => ({
          params: {
            slug: curr?.paths?.[id]?.split('/').splice(1),
          },
          locale: id,
        })),
        ...acc,
      ],
      [],
    )
    .filter((path) => path.params.slug?.length);

  return {
    paths,
    fallback: true,
  };
};
